const MAndA = [
	{
		imgOne: '/img/creds/fpglogo.png', 
		imgTwo: '/img/creds/macquarielogo.png',
		client: 'Fresh Produce Group',
		role: 'Financial adviser to Fresh Produce Group on their c.A$350m partnership with Macquarie Asset Management',
		time: 'Dec 2024',
	},
	{ 
		imgOne: '/img/creds/PetspirationGroup.png',
		imgTwo: '/img/creds/woolworthsgroup.png',
		client: 'Petspiration Group',
		role: 'Financial Adviser to Petspiration Group on their $1.5bn partnership with The Woolworths Group via the sale of a 55% equity interest',
		time: 'Jan 2024',
	},
	{ 
		imgOne: '/img/creds/PetspirationGroup.png',
		imgTwo: '/img/creds/bfplogo.png',
		client: 'Petspiration Group',
		role: 'Financial Adviser to Petspiration Group on their acquisiton of The Best Friends Pets Group from The Growth Fund',
		time: 'Jul 2022',
	},
	{ 
		imgOne: '/img/creds/leeflogo.png',
		imgTwo: '/img/creds/ILS-logo.png',
		client: 'Leef Independent Living',
		role: 'Financial Adviser to Leef Independent Living on its sale to ILS Group',
		time: 'Jul 2022',
	},
	{ 
		imgOne: '/img/creds/PAG.png',
		imgTwo: '/img/creds/River.png',
		client: 'The Cheesecake Shop',
		role: 'Financial Adviser to PAG on the sale of The Cheesecake Shop to River Capital',
		time: 'Jan 2022',
	},
	{ 
		imgOne: '/img/creds/Nabenet.png',
		imgTwo: '/img/creds/Altius.png',
		client: 'Nabenet',
		role: 'Financial Adviser to Nabenet on its sale to Altius Group',
		time: 'Oct 2021',
	},
	{ 
		imgOne: '/img/creds/FiveV.png',
		imgTwo: '/img/creds/Openway.png',
		client: 'Five V Capital',
		role: 'Financial Adviser to Five V Capital on the acquisitions of Annex Foods, Table of Plenty and Metro Foods Co to form Openway Food Co',
		time: 'Jun 2021',
	},
	/*{ 
		imgOne: '/img/creds/FiveV.png',
		client: 'Five V Capital',
		role: 'Financial Adviser to Five V Capital on its healthy foods bolt-on aquisition',
		time: 'Jun 2021',
	},
	{ 
		imgOne: '/img/creds/FiveV.png',
		imgTwo: '/img/creds/TableofPlenty.png',
		client: 'Five V Capital',
		role: 'Financial Adviser to Five V Capital on its aquisition of Table of Plenty',
		time: 'Apr 2021',
	},
	{ 
		imgOne: '/img/creds/FiveV.png',
		imgTwo: '/img/creds/Annex.png',
		client: 'Five V Capital',
		role: 'Financial Adviser to Five V Capital on its aquisition of Annex Foods Brands',
		time: 'Apr 2021',
	}, */
	{ 
		imgOne: '/img/creds/vend.png',
		imgTwo: '/img/creds/Lightspeed.png',
		client: 'Vend Limited',
		role: 'Financial Adviser to Vend on its US$350m sale to Lightspeed POS Inc',
		time: 'Apr 2021',
	},
	{ 
		imgOne: '/img/creds/funlab-logo-new.png',
		imgTwo: '/img/creds/TPG_Capital_logo.png',
		client: 'TPG Capital',
		role: 'Financial Adviser to TPG Capital on its acquisition of Funlab',
		time: 'Mar 2021',
	},
	{ 
		imgOne: '/img/creds/SMGHealth-logo-sml.png',
		imgTwo: '/img/creds/morneaushepell.png',
		client: 'SMG Health Pty Ltd',
		role: 'Financial Adviser to SMG Health on its sale to Morneau Shepell',
		time: 'Feb 2021',
	},
	{ 
		imgOne: '/img/creds/catch.png',
		imgTwo: '/img/creds/WES.png',
		client: 'Catch Group Limited',
		role: 'Financial Adviser to Catch Group on its A$230m sale to Wesfarmers',
		time: 'Aug 2019',
	},
	{ 
		imgOne: '/img/creds/TIC.png',
		imgTwo: '/img/creds/Pact.png',
		client: 'TIC Group Pty Ltd',
		role: 'Financial Adviser to TIC Group on its A$122m sale of TIC Retail Accessories to Pact Group',
		time: 'Oct 2018',
	},
	{ 
		imgOne: '/img/creds/RSEA.png',
		imgTwo: '/img/creds/ICG.png',
		client: 'RSEA Management Team',
		role: 'Financial Adviser to Management on its partnership with Intermediate Capital Group to effect the Management Buy-Out of RSEA Safety',
		time: 'May 2018',
	},
	{ 
		imgOne: '/img/creds/Probe.png',
		imgTwo: '/img/creds/FiveV.png',
		client: 'Probe Group',
		role: 'Financial Adviser to The Probe Group on its partnership with Five V Capital to acquire Salmat Limited\'s Contact Centre business',
		time: 'May 2018',
	},
	{ 
		imgOne: '/img/creds/catch.png',
		imgTwo: '/img/creds/LuxGroup.png',
		client: 'Catch Group Limited',
		role: 'Financial Adviser to Catch Group on the interconditional asset swap of its online travel businesses with Lux Group\'s online product business',
		time: 'Dec 2016',
	},
	{ 
		imgOne: '/img/creds/SnackBrands.jpg',
		imgTwo: '/img/creds/UniversalRobina.png',
		client: 'Snack Brands',
		role: 'Financial Adviser to Snack Brands Australia on its sale to Universal Robina',
		time: 'Aug 2016',
	},
];

const CapitalRaise = [
	{ 
		imgOne: '/img/creds/Hireup.jpg',
		imgTwo: '/img/creds/Seek.png',
		client: 'Hireup Pty Ltd',
		role: 'Financial Adviser to Hireup on its c.$60m investment from SEEK',
		time: 'Aug 2021',
	},
	{ 
		imgOne: '/img/creds/Deliciou.png',
		imgTwo: '/img/creds/StrayDog.jpg',
		client: 'Deliciou Pty Ltd',
		role: 'Financial Adviser to Deliciou on its equtiy capital raise including from Stray Dog Capital',
		time: 'Apr 2021',
	},
	{ 
		imgOne: '/img/creds/jigsaw.png',
		client: 'Jigsaw Australia',
		role: 'Financial Adviser to Jigsaw on its impact debt capital raising',
		time: 'Apr 2021',
	},
	{ 
		imgOne: '/img/creds/IDA.jpg',
		client: 'IDA Property Group',
		role: 'Financial Adviser to IDA Property on its external debt capital raising',
		time: 'Jul 2020',
	},
	{ 
		imgOne: '/img/creds/Soulfresh.png',
		imgTwo: '/img/creds/True.png',
		client: 'Soulfresh Group Pty Ltd',
		role: 'Financial Adviser to Soulfresh Group on its c.$50m investment from True',
		time: 'Dec 2019',
	},
	{ 
		imgOne: '/img/creds/Vivi.png',
		imgTwo: '/img/creds/RiversideAC.png',		
		client: 'Vivi International Pty Ltd',
		role: 'Financial Adviser to Vivi on its equity capital raise and royalty financing with Riverside Acceleration Capital',
		time: 'Dec 2019',
	},	
	{ 
		imgOne: '/img/creds/Hireup.jpg',
		client: 'Hireup Pty Ltd',
		role: 'Financial Adviser to Hireup on its c.$5m equity capital raise',
		time: 'Dec 2018',
	},
		{ 
		imgOne: '/img/creds/Vitaco.png',
		imgTwo: '/img/creds/NextCapital.png',
		client: 'Vitaco Holdings Limited',
		role: 'Independent Financial Adviser to Next Capital in relation to the A$292m IPO of Vitaco',
		time: 'Sep 2015',
	},
];

export { MAndA, CapitalRaise };

